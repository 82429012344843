<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <div class="up_box" v-loading="pageLoading">
<!--      <div class="up_date">-->
<!--        <el-date-picker-->
<!--          v-model="dateScope"-->
<!--          @change="dateChange"-->
<!--          type="daterange"-->
<!--          format="yyyy-MM-dd"-->
<!--          value-format="yyyy-MM-dd"-->
<!--          align="right"-->
<!--          unlink-panels-->
<!--          range-separator="至"-->
<!--          start-placeholder="开始日期"-->
<!--          end-placeholder="结束日期"-->
<!--          :picker-options="pickerOptions">-->
<!--        </el-date-picker>-->
<!--      </div>-->
      <div class="up_list">
        <div class="up_item" v-for="(item,index) in chartList" :key="index">
          <div class="up_head">
            <div class="item_context">
              <div>{{ item.title }}</div>
              <el-tooltip class="item" effect="dark" :content="item.tooltip" placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <el-divider v-if="index!=chartData.length-1" direction="vertical"></el-divider>
          </div>
          <div class="up_body">
            <div v-if="item.payUserCount">{{ chartData[item.mainKey]+item.units }}<span class="up_text">{{'('+chartData[item.payUserCount]+'/'+chartData[item.coverNum]+')'}}</span></div>
            <div v-else-if="item.centerStoreServiceCount">{{ chartData[item.mainKey]+item.units }}<span class="up_text">{{'('+chartData[item.centerStoreServiceCount]+'/'+chartData[item.coverNum]+')'}}</span></div>
            <div v-else-if="item.satelliteStoreServiceCount">{{ chartData[item.mainKey]+item.units }}<span class="up_text">{{'('+chartData[item.satelliteStoreServiceCount]+'/'+chartData[item.coverNum]+')'}}</span></div>
            <div v-else>{{ chartData[item.mainKey]+item.units }}</div>
            <div class="up_text" v-if="item.coverProportion">占比：{{ chartData[item.coverProportion] }}%</div>
            <div class="up_text" v-if="item.allUserCount||item.allUserCount==0">总用户数：{{ chartData[item.allUserCount] }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart_box" v-loading="pageLoading">
      <div class="chart_item">
        <div class="chart_title">最近到店时间</div>
        <div ref="echart1" style="width: 100%;height: 92%;"></div>
      </div>
      <div class="chart_item">
        <div class="chart_title">最近支付时间</div>
        <div ref="echart2" style="width: 100%;height: 92%;"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands, dateFormat } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";
import echarts from "echarts";
export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        pageLoading: false,                                     //页面加载状态
        dateScope: [],                                          //时间范围
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        defaultDateRange: [new Date(), this.calculateDateByDays(30)],
        chartList: [
          { title: '用户数', mainKey: 'coverNum', units: '人', allUserCount: 'allUserCount', coverProportion:'coverProportion', tooltip: '人群包总计用户数范围；占总用户：所选人群占总用户的百分比；转化占比： 转化用户占所选时间范围卫星店/工坊店全部消费用户' },
          // { title: '客单价', mainKey: 'atv', units: '元', tooltip: '人群包总计用户在所选日期的客单价（所有用户在30日内实际支付总额/支付用户数）' },
          { title: '支付占比', mainKey: 'payProportion', units: '%', coverNum: 'coverNum', payUserCount: 'payUserCount', tooltip: '人群包总计用户在所选日期的有支付行为的用户占比' },
          { title: '工坊店消费占比', mainKey: 'centerStoreServiceProportion', units: '%', coverNum: 'coverNum', centerStoreServiceCount:'centerStoreServiceCount', tooltip: '人群包总计用户在所选日期的有工坊店到店服务的用户占比' },
          { title: '卫星店消费占比', mainKey: 'satelliteStoreServiceProportion', units: '%', coverNum: 'coverNum', satelliteStoreServiceCount: 'satelliteStoreServiceCount', tooltip: '人群包总计用户在所选日期的有卫星店到店服务的用户占比' },
        ],
        chartData: {},
        echartsArr: [],
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "人群管理", path: "/crowdManagement/list" },
        {
          name: this.pageTitle
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      
    },
    // 表格内操作
    tableHandle(type, row) {

    },
    // 重置搜索数据
    resetSearchData(e) {

    },

    calculateDateByDays(days) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.dateScope = [dateFormat(start), dateFormat(end)];
    },

    setEcharts(){
      let option1 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.arrivalTimeTypeList,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: this.chartData.arrivalTimeProportionList
          }
        ]
      };
      let option2 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.payTimeTypeList.map(function (str) {
              return str.replace(' ', '\n');
            }),
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: this.chartData.payTimeProportionList
          }
        ]
      };
      let myChart1 = echarts.init(this.$refs.echart1);
      let myChart2 = echarts.init(this.$refs.echart2);
      myChart1.setOption(option1);
      myChart2.setOption(option2);
    },

    // 时间范围选择
    dateChange(){
      this.getCrowdData();
    },
    // 获取人群洞察数据
    getCrowdData(){
      this.pageLoading = true;
      this.$store.dispatch('management/crowdManagementChart',{
        crowdNo: this.$route.query.crowdNo,
        startTime: this.dateScope[0],
        endTime: this.dateScope[1],
      }).then(res => {
        this.chartData = res.data;
        this.echartsArr = [];
        this.echartsArr.push({
          title: '最近到店时间',
          arrivalTimeTypeList: res.data.arrivalTimeTypeList,
          arrivalTimeProportionList: res.data.arrivalTimeProportionList,
          echartsId: 'echart1'
        },{
          title: '最近支付时间',
          payTimeTypeList: res.data.payTimeTypeList,
          payTimeProportionList: res.data.payTimeProportionList,
          echartsId: 'echart2'
        })
        this.setEcharts();
        this.pageLoading = false;
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取默认一个月时间
    this.calculateDateByDays();
    // 获取人群洞察数据
    this.getCrowdData();
    // 获取当前路由的名称
    this.isAdd = this.$route.name === "crowdManagementAdd";
    this.pageTitle = this.$route.meta.title;
    if (!this.isAdd) {
      // this.getPackageInfo(this.$route.params.id);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;

  }
  .up_box{
      background: #fff;
      margin-top: 20px;
      padding: 15px 30px;
      box-sizing: border-box;
      text-align: end;
    .up_date{
      margin-bottom: 30px;
    }
    .up_list{
      display: flex;
      .up_item{
        width: 20%;
        height: 120px;
        position: relative;
        .up_head{
          height: 100%;
          display: flex;
          color: #999;
          .item_context{
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: auto;
            i{
              margin-bottom: 10px;
              margin-left: 5px;
              cursor: pointer;
            }
          }
          .el-divider{
            height: 100%;
            margin: 0 30px;
          }
        }
        .up_body{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0,-50%);
          .up_text{
            color: #999;
            font-size: 12px;
          }
          div{
            text-align: start;
          }
        }
      }
    }
  }
  .chart_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chart_item{
      width: 49%;
      height: 400px;
      background: #fff;
      margin-top: 2%;
      padding: 20px;
      box-sizing: border-box;
      .chart_title{
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}
</style>
